<template>
  <div>
    <!-- Header -->
    <div class="d-md-flex align-items-center">
      <div class="w-100">
        <VuePerfectScrollbar class="scrlbar" style="height: 430px">
          <div>
            <ul ref="chatLog" class="p-3 chat-list">
              <li
                class="d-flex align-items-start"
                :class="{
                  'flex-row-reverse right-msg': chat.user_id===authId,
                  'mt-4 left-msg': index,
                }"
                v-for="(chat, index) in chatConversation"
                :key="index"
              >
                <div
                  class="chat p-3 mb-2"
                  :class="{
                    'chat-sent-chat bg-primary text-white': chat.user_id===authId,
                    'bg-light': chat.user_id!==authId,
                  }"
                >
                  <span>{{ chat.msg_text }}</span>
                </div>
              </li>
            </ul>
          </div>
        </VuePerfectScrollbar>
        <div class="d-flex border-top p-3">
          <b-form-input
            class="w-100 border-0"
            placeholder="Type Your Message Here"
            v-model="newMessage"
            type="text"
            @keyup.enter="addMessage"
          />
          <b-button
            variant="primary"
            class="float-right rounded-circle b-avatar"
            @click="addMessage"
          >
            <feather type="send" class="feather-sm"></feather>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
export default {
  name: "RecentChats",
  props: {
    order: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
  },
  data: () => ({
    title: "RecentChats",
    newMessage: "",
    chatConversation: [],
    convo: null
  }),
  components: {
    VuePerfectScrollbar,
  },
  computed: {
    ...mapState({
      orderMsgs: (state) => state.order.orderMsgs,
      authId: (state) => state.auth.authId
    }),
    ...mapGetters(["msgById"])
  },
  mounted() {
    this.convo = setInterval(() => {
      this.loadConversations();
    }, 10000)
    this.loadConversations();
  },
  methods: {
    ...mapActions(["saveMessage","getMessagesByOrder"]),
    addMessage() {
      if (this.newMessage) {
        this.saveMessage({
          user_id: this.authId,
          entity_id: this.order,
          msg_text: this.newMessage,
          message_type: 1,
          msg_time: moment().format('YYYY-MM-DD HH:mm:ss')
        }).then(() => {  
          this.newMessage = "";
          this.loadConversations();
        });
      }
    },
    loadConversations(){
      this.getMessagesByOrder({id: this.order}).then((res) => {
        this.chatConversation = res
      })
    }
  },
  destroyed() {
    clearInterval(this.convo);
  }
};
</script>